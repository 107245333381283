$(document).ready(function(){
  //Scrolling
  $.scrollify({
    section : ".scrollify-block",
    sectionName : "section-name",
    interstitialSection : "",
    easing: "easeOutExpo",
    scrollSpeed: 1100,
    offset : 0,
    scrollbars: true,
    standardScrollElements: "",
    setHeights: true,
    overflowScroll: true,
    updateHash: true,
    touchScroll:true,
  });

  //Navigation block
  let nav_item_count = $('.scrollify-block').length;
  for (let i=1; i<=nav_item_count; i++) {
    currentHtml = $('nav.nav').html();
    $('nav.nav').html(currentHtml+'<div class="nav-item"></div>');
  }

  let screenHeight = $(window).height();
  let first = $('.nav-item:nth-child(1)');
  let second = $('.nav-item:nth-child(2)');
  let third = $('.nav-item:nth-child(3)');

  if ( window.pageYOffset < screenHeight) {
    first.addClass('is-active');
  } else if (window.pageYOffset < screenHeight*2) {
    second.addClass('is-active');
  } else if (window.pageYOffset < screenHeight*3) {
    third.addClass('is-active');
  }
  window.onscroll = function() {
    if ( window.pageYOffset < screenHeight-35) {
      first.addClass('is-active');
      second.removeClass('is-active');
      third.removeClass('is-active');
    } else if (window.pageYOffset < screenHeight*2-35) {
      first.removeClass('is-active');
      second.addClass('is-active');
      third.removeClass('is-active');
    } else if (window.pageYOffset < screenHeight*3-35) {
      first.removeClass('is-active');
      second.removeClass('is-active');
      third.addClass('is-active');
    }
  };

  //Ajax form
  $('#credit-card-form-1, #credit-card-form-2').on('submit', function (e) {
    e.preventDefault();
    $.post(
      "../landing-card/action.php", {
        userPhone: $(this).find('[name="user-phone"]').val(),
        userEmail: $(this).find('[name="user-email"]').val(),
      },
      success
    );
    function success () {
      alert('Дякуємо, ваші дані відправлені, очікуйте дзвінка');
    }
  });
  $.post()
});

